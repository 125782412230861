import { useQuery } from '@tanstack/react-query';
import { ENDPOINTS } from 'api';
import { getOrganization } from 'api/organizations';
import { events, mixpanelService } from 'app/analytics';
import { useCallback, useContext } from 'react';
import { openUrl } from 'utils';
import useApiCall from './useApiCall';
import { ViewContext } from 'components/lib';

export const useOrganization = () => {
  const context = useContext(ViewContext);
  const { makeApiCall, isLoading } = useApiCall();

  const { data: organization, refetch: refetchOrganization } = useQuery({
    queryKey: ['organization'],
    queryFn: getOrganization,
  });

  const handleCreateStripeSession = useCallback(async () => {
    mixpanelService.trackEvent(events.MANAGE_STRIPE_ORG_CLICK);
    const endpoint = ENDPOINTS.ORG_CREATE_CUSTOMER_PORTAL_SESSION;
    const requestData = {
      redirect_url: window.location.origin,
    };

    try {
      const data = await makeApiCall(endpoint, 'post', requestData);
      if (data.portal_url) openUrl(data.portal_url);
    } catch (err) {
      context?.current && context.current.handleError(err);
    }
  }, [makeApiCall]);

  const manageEnvs = () => {
    mixpanelService.trackEvent(events.ORG_MANAGE_ENV_CLICK);
    const options = organization?.baseUrlOptions?.map((option) => ({
      label: option.name,
      value: option.baseUrl,
    }));
    context.modal.show(
      {
        title: 'Manage environments',
        form: {
          baseUrl: {
            label: 'Default environment',
            type: 'select',
            default: organization?.baseUrl,
            options: options,
          },
          baseUrlOptions: {
            label: 'Edit options',
            type: 'optionsEditor',
            options: options,
          },
        },
        buttonText: 'Submit',
        url: '/platform/v2/organizations',
        method: 'PATCH',
      },
      () => {
        refetchOrganization();
      },
    );
  };

  return {
    organization,
    refetchOrganization,
    handleCreateStripeSession,
    manageEnvs,
  };
};
