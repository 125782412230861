import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { ClassHelper } from 'components/lib';
import Style from './dropdown.tailwind.js';

export function Dropdown({ children }) {
  return <RadixDropdownMenu.Root>{children}</RadixDropdownMenu.Root>;
}

function Trigger({ children }) {
  return (
    <RadixDropdownMenu.Trigger
      className="cursor-default select-none focus-visible:outline-none"
      asChild
    >
      {children}
    </RadixDropdownMenu.Trigger>
  );
}

Dropdown.Trigger = Trigger;

function DropdownMenu({ children }) {
  return (
    <RadixDropdownMenu.Portal>
      <RadixDropdownMenu.Content
        align="end"
        className="mt-1 overflow-y-auto max-h-60 rounded-md bg-background-700 py-2 text-left shadow backdrop-blur border border-gray-500"
      >
        {children}
      </RadixDropdownMenu.Content>
    </RadixDropdownMenu.Portal>
  );
}

Dropdown.Menu = DropdownMenu;

function DropdownMenuItem({
  children,
  disabled = false,
  onSelect = () => {},
  ...props
}) {
  const DropdownMenuItemStyle = ClassHelper(Style, {
    className: props.className,
    disabled,
  });
  return (
    <RadixDropdownMenu.Item
      disabled={disabled}
      className={DropdownMenuItemStyle}
      onSelect={onSelect}
    >
      {children}
    </RadixDropdownMenu.Item>
  );
}

Dropdown.MenuItem = DropdownMenuItem;
