import { PrAgentStep } from './prAgentStep';
import { Icon, Link } from 'components/lib';
import GitScreenshot from './GitScreenshot.svg';

export const UsageSection = () => (
  <PrAgentStep
    title="Step 3 (For all users) - Start reviewing PRs and more"
    subtitle={
      <>
        In any Pull Request conversation, use commands such as
        <Code>{'/review'}</Code>
        to trigger PR review on demand,
        <Code>{'/describe'}</Code>
        to get detailed analysis and code suggestions, or
        <Code>{'/ask {question}'}</Code>
        in the context of your PR.
      </>
    }
  >
    <div className="flex gap-6">
      <div className="flex gap-1 items-center">
        <Link
          url="https://pr-agent-docs.codium.ai/"
          text="Documentation"
          className="text-indigo-300 hover:underline font-medium"
        />
        <Icon image="external-link" color="purple" />
      </div>
      <div className="flex gap-1 items-center">
        <Link
          url="https://chromewebstore.google.com/detail/pr-agent-chrome-extension/ephlnjeghhogofkifjloamocljapahnl?hl=en"
          text="Chrome extension"
          className="text-indigo-300 hover:underline font-medium"
        />
        <Icon image="external-link" color="purple" />
      </div>
    </div>
    <img src={GitScreenshot} alt="pr review" />
  </PrAgentStep>
);

const Code = ({ children }) => {
  return (
    <span className="bg-teal-400 bg-opacity-10 rounded px-1 mx-1 inline-flex">
      <code className="text-teal-400 text-xs">{children}</code>
    </span>
  );
};
