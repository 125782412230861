import { apiService, ENDPOINTS } from 'api';
import { events } from 'app/analytics';

export const getOrganization = async () => {
  try {
    const data = await apiService.callApi({
      endpoint: ENDPOINTS.ORGANIZATIONS,
      errorEvent: events.GET_ORGANIZATIONS_ERROR,
    });
    return data?.organization;
  } catch (err) {
    return err;
  }
};
