import { Button, Dropdown, Icon, Tooltip } from 'components/lib';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { ssoSetup } from 'api/teams';
import { useOrganization } from '../../components/hooks/useOrganization';

export const ManageOrgDropdown = ({ createTeam }) => {
  const { organization, manageEnvs } = useOrganization();

  return (
    <div>
      <Dropdown>
        <>
          <Dropdown.Trigger>
            <div>
              <Button
                small
                text={
                  <>
                    {'Manage Organization'}
                    <ChevronDownIcon />
                  </>
                }
                className="flex items-center gap-2"
              />
            </div>
          </Dropdown.Trigger>
          <Dropdown.Menu>
            <>
              <div>
                <Dropdown.MenuItem onSelect={createTeam}>
                  <div>Create a team</div>
                </Dropdown.MenuItem>
                {organization?.baseUrlOptions && (
                  <Dropdown.MenuItem onSelect={manageEnvs}>
                    <div>Manage environments</div>
                  </Dropdown.MenuItem>
                )}
                <Dropdown.MenuItem
                  onSelect={async () => {
                    const { portal_url } = await ssoSetup(window.location.href);
                    window.open(portal_url?.link, '_blank');
                  }}
                >
                  <div className="flex gap-2 items-center">
                    <span>Manage SSO</span>
                    <Tooltip
                      side="bottom"
                      content={
                        <p className="leading-6 text-xs">
                          Copy SSO management <br />
                          access URL to share with <br />
                          your SSO admin
                        </p>
                      }
                    >
                      <div
                        onClick={async (e) => {
                          e.stopPropagation();
                          const { portal_url } = await ssoSetup(
                            window.location.href,
                          );
                          await navigator.clipboard.writeText(portal_url?.link);
                        }}
                      >
                        <Icon asChild image="copy" color="#AEA1F1" size={14} />
                      </div>
                    </Tooltip>
                  </div>
                </Dropdown.MenuItem>
              </div>
              <Dropdown.MenuItem disabled>
                <div className="flex gap-2 items-center">
                  <span className="text-gray-500">Cancel Plan</span>
                  <Tooltip
                    side="bottom"
                    content={
                      <p className="leading-6 text-xs">
                        This account is part of an enterprise contract.
                        <br />
                        To cancel your plan, please contact support@codium.ai
                      </p>
                    }
                  >
                    <div>
                      <Icon asChild image="info" color="#AEA1F1" size={14} />
                    </div>
                  </Tooltip>
                </div>
              </Dropdown.MenuItem>
            </>
          </Dropdown.Menu>
        </>
      </Dropdown>
    </div>
  );
};
