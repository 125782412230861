/***
 *
 *   usePlans hook
 *   fetch, format and return the price plans
 *
 **********/

import { useQuery } from '@tanstack/react-query';
import { getPlans } from 'api/teams';
import JetBrainLogo from 'assets/JetBrains.svg';
import VSCode from 'assets/VSCode.svg';
import { useEffect, useState } from 'react';
import { openUrl } from 'utils';
import { useTeam } from './useTeam';
import { PLAN_NAMES } from './../../constants';

const links = [
  {
    name: 'VSCode',
    imgUrl: VSCode,
    url: 'vscode:extension/Codium.codium',
  },
  {
    name: 'JetBrains',
    imgUrl: JetBrainLogo,
    url: 'https://plugins.jetbrains.com/plugin/21206-codiumai--integrity-agent-powered-by-gpt-3-5-4',
  },
];

const TeamPlanTrialMessage = 'Your trial includes all Teams features';
export const usePlans = () => {
  const { handleCreateStripeSession, isLoading: isLoadingCreateStripeSession } =
    useTeam();
  const { data, isLoading, isError } = useQuery({
    queryKey: ['plans'],
    queryFn: getPlans,
  });
  const [enrichedPlans, setEnrichedPlans] = useState([]);

  useEffect(() => {
    if (data?.plans) enrichPlans(data);
  }, [data?.plans, isLoadingCreateStripeSession]);

  const enrichPlans = ({ plans, activePlanName, isTrialing }) => {
    const enrichedPlans = plans?.map((plan) => ({
      ...plan,
      ...(enrichedPlanData[plan?.name] || {}),
      isActive: plan?.name === activePlanName,
      isLoading:
        plan?.name === PLAN_NAMES.TEAMS && isLoadingCreateStripeSession,
      isActionDisabled:
        isLoadingCreateStripeSession ||
        (plan?.name === PLAN_NAMES.TEAMS &&
          activePlanName === 'Teams' &&
          !isTrialing),
      trialMessage:
        plan?.name === PLAN_NAMES.TEAMS && isTrialing && TeamPlanTrialMessage,
    }));
    setEnrichedPlans(enrichedPlans);
  };

  const enrichedPlanData = {
    [PLAN_NAMES.DEVELOPER]: {
      buttonText: 'Quick Install',
      buttonVariant: 'outline',
      dropdown: {
        show: true,
        options: links,
      },
      action: null,
    },

    [PLAN_NAMES.TEAMS]: {
      buttonText: 'Subscribe',
      buttonVariant: 'primary',
      dropdown: null,
      action: (price) => {
        handleCreateStripeSession({ isManage: false, priceId: price?.id });
      },
    },

    [PLAN_NAMES.ENTERPRISE]: {
      buttonText: 'Contact us',
      buttonVariant: 'outline',
      dropdown: null,
      action: () => {
        openUrl('https://www.codium.ai/contact/');
      },
    },
  };

  return { plans: enrichedPlans, isLoading, isError };
};
