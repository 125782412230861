import { getAuth } from 'firebase/auth';

const Settings = require('settings.json');
const env = process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV;
const CONNECT_ACCOUNT_REDIRECT_URI = `v1/auth/connect-account`;

const codiumRedirectPath = Settings[env].codium_redirect_path;
const githubClientId = Settings[env].github_client_id;
const bitbucketClientId = Settings[env].bitbucket_client_id;
const gitlabClientId = Settings[env].gitlab_client_id;
const githubAuthorizeURL = 'https://github.com/login/oauth/authorize';
const bitbucketAuthorizeURL = 'https://bitbucket.org/site/oauth2/authorize';
const gitlabAuthorizeURL = 'https://gitlab.com/oauth/authorize';

const baseURL = `${codiumRedirectPath}/${CONNECT_ACCOUNT_REDIRECT_URI}`;

const thisURL = () =>
  encodeURIComponent(window.location.origin + window.location.pathname);

const getUserToken = async () => {
  const auth = getAuth();
  const user = auth.currentUser;
  return user ? await user.getIdToken(true) : '';
};

export const githubLoginURL = async () => {
  const token = await getUserToken();
  if (!token) {
    return '';
  }
  const state = btoa(`${token};github;${thisURL()}`);
  const redirectURI = `${baseURL}/${state}`;
  const scope = 'read:user';

  const url = new URL(githubAuthorizeURL);
  url.searchParams.append('client_id', githubClientId);
  url.searchParams.append('redirect_uri', redirectURI);
  url.searchParams.append('scope', scope);

  return url.toString();
};

export const gitlabLoginURL = async () => {
  const token = await getUserToken();
  if (!token) {
    return '';
  }
  const state = btoa(`${token};gitlab;${thisURL()}`);
  const redirectURI = `${baseURL}`;
  const scope = 'read_user';
  const responseType = 'code';

  const url = new URL(gitlabAuthorizeURL);
  url.searchParams.append('client_id', gitlabClientId);
  url.searchParams.append('redirect_uri', redirectURI);
  url.searchParams.append('response_type', responseType);
  url.searchParams.append('scope', scope);
  url.searchParams.append('state', state);

  return url.toString();
};

export const bitbucketLoginURL = async () => {
  const token = await getUserToken();
  if (!token) {
    return '';
  }
  const state = btoa(`${token};bitbucket;${thisURL()}`);
  const redirectURI = `${baseURL}/${state}`;
  const responseType = 'code';

  const url = new URL(bitbucketAuthorizeURL);
  url.searchParams.append('client_id', bitbucketClientId);
  url.searchParams.append('redirect_uri', redirectURI);
  url.searchParams.append('response_type', responseType);

  return url.toString();
};
