/***
 *
 *   AUTH NAV
 *   Navigation used on the auth pages. Renders a dashboard link
 *   if the user is signed in or a sign-up link if they are not
 *
 *   PROPS
 *   transparent: apply a transparent background color (boolean, optional)
 *
 **********/

import { Fragment, useContext } from 'react';
import {
  AuthContext,
  Logo,
  Link,
  Button,
  Content,
  ClassHelper,
} from 'components/lib';
import Style from './auth.tailwind.js';

export function AuthNav(props) {
  const css = ClassHelper(Style, {
    wrapper: true,
    color: !props.transparent,
    transparent: props.transparent,
  });

  return (
    <section className={css}>
      <Content>
        <Logo className={Style.logo} />
        <div className={Style.nav + ' ' + Style.rightHeader}>
          Meaningful tests for busy devs
        </div>
      </Content>
    </section>
  );
}
