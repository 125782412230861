import { useMutation, useQuery } from '@tanstack/react-query';
import { ENDPOINTS, apiService } from 'api';
import Bitbucket from 'assets/Bitbucket.svg';
import Github from 'assets/Github.svg';
import Gitlab from 'assets/Gitlab.svg';
import {
  Animate,
  Button,
  Card,
  Icon,
  Tooltip,
  ViewContext,
  Dropdown,
  Loader,
  TitleRow,
  Table,
  useInvite,
  Badge,
} from 'components/lib';
import { useContext, useEffect, useState } from 'react';
import { events, mixpanelService } from '../../app/analytics';
import { useOrganization } from 'components/hooks/useOrganization';
import { find, isEmpty } from 'lodash';
import { TIER_LEVELS } from '../../constants';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { PortalNav } from 'components/nav/sub/portal';
import { ManageOrgDropdown } from './manageOrgDropdown';
import { useTeam } from '../../components/hooks/useTeam';

const gitProvidersConnections = {
  github: {
    icon: Github,
  },
  bitbucket: {
    icon: Bitbucket,
  },
  gitlab: {
    icon: Gitlab,
  },
};

export const getOrganizationMembers = async () => {
  try {
    const data = await apiService.callApi({
      endpoint: ENDPOINTS.ORG_MEMBERS,
      errorEvent: events.GET_ORG_MEMBERS_ERROR,
    });
    return data;
  } catch (err) {
    return err;
  }
};

export const Organization = () => {
  const context = useContext(ViewContext);
  const [users, setUsers] = useState([]);
  const { organization, handleCreateStripeSession } = useOrganization();
  const { addTeam } = useTeam();
  const { inviteMembersToOrg } = useInvite();

  const {
    data: orgMembers,
    isPending,
    refetch: refetchOrgMembers,
  } = useQuery({
    queryKey: ['orgMembers'],
    queryFn: getOrganizationMembers,
  });

  const { mutateAsync: updateUser } = useMutation({
    mutationFn: async (data) => {
      return apiService.callApi({
        endpoint: `${ENDPOINTS.USERS}`,
        method: 'patch',
        data,
      });
    },
    onSuccess: () => refetchOrgMembers(),
  });

  const TeamPermission = ({ user, team }) => {
    if (!team) return;
    const permission = user.permission === 'owner' ? 'Admin' : 'Member';

    return (
      <div>
        <Dropdown>
          <>
            <Dropdown.Trigger>
              <div className="flex items-center gap-1 cursor-pointer text-brand1-300">
                <span>{permission}</span>
                <ChevronDownIcon />
              </div>
            </Dropdown.Trigger>
            <Dropdown.Menu>
              <>
                <Dropdown.MenuItem
                  onSelect={() =>
                    updateUser({
                      attributes: { permission: 'user' },
                      user_id: user.id,
                    })
                  }
                >
                  Member
                </Dropdown.MenuItem>
                <Dropdown.MenuItem
                  onSelect={() =>
                    updateUser({
                      attributes: { permission: 'owner' },
                      user_id: user.id,
                    })
                  }
                >
                  Admin
                </Dropdown.MenuItem>
              </>
            </Dropdown.Menu>
          </>
        </Dropdown>
      </div>
    );
  };

  const Environment = ({ user }) => {
    const options = organization?.baseUrlOptions;
    if (isEmpty(options)) return;

    const currentOption = options.find(
      (option) => option.baseUrl === user?.baseUrl,
    );

    return (
      <div>
        <Dropdown>
          <>
            <Dropdown.Trigger>
              <div className="flex items-center gap-1 cursor-pointer text-brand1-300">
                <span>{currentOption?.name ?? user?.baseUrl}</span>
                <ChevronDownIcon />
              </div>
            </Dropdown.Trigger>
            <Dropdown.Menu>
              <>
                {options.map((option) => (
                  <Dropdown.MenuItem
                    key={`Environment-${option.name}`}
                    onSelect={() =>
                      updateUser({
                        attributes: { baseUrl: option.baseUrl },
                        user_id: user.id,
                      })
                    }
                  >
                    <div>
                      <div className="font-semibold">{option.name}</div>
                      <div className="text-gray-500">{option.baseUrl}</div>
                    </div>
                  </Dropdown.MenuItem>
                ))}
              </>
            </Dropdown.Menu>
          </>
        </Dropdown>
      </div>
    );
  };

  const getTeamLabel = (team, user) => {
    if (!team) return;
    return (
      <div className="flex items-center gap-1">
        <TeamPermission user={user} team={team} /> at {team.name}
        {team.tier_level === TIER_LEVELS.PAID && (
          <Tooltip
            side="top"
            content={<p className="leading-6 text-xs">Independent team</p>}
          >
            <div>
              <Icon asChild image="info" color="#AEA1F1" size={14} />
            </div>
          </Tooltip>
        )}
      </div>
    );
  };

  const OrgPermission = ({ user }) => {
    const permission =
      user.organization_permission === 'owner' ? 'Admin' : 'Member';

    return (
      <div>
        <Dropdown>
          <>
            <Dropdown.Trigger>
              <div className="flex items-center gap-1 cursor-pointer text-brand1-300">
                <span>{permission}</span>
                <ChevronDownIcon />
              </div>
            </Dropdown.Trigger>
            <Dropdown.Menu>
              <>
                <Dropdown.MenuItem
                  onSelect={() =>
                    updateUser({
                      attributes: { organization_permission: 'user' },
                      user_id: user.id,
                    })
                  }
                >
                  Member
                </Dropdown.MenuItem>
                <Dropdown.MenuItem
                  onSelect={() =>
                    updateUser({
                      attributes: { organization_permission: 'owner' },
                      user_id: user.id,
                    })
                  }
                >
                  Admin
                </Dropdown.MenuItem>
              </>
            </Dropdown.Menu>
          </>
        </Dropdown>
      </div>
    );
  };

  const OrgTitle = () => {
    return (
      <div className="flex items-center gap-3">
        <span>Organization</span>

        <Badge
          text={
            organization?.licenses > 0
              ? `${organization?.allowed_users}/${organization?.licenses} LICENSES`
              : `${organization?.allowed_users} USERS`
          }
          className="bg-gray-500 border-gray-500"
        />
      </div>
    );
  };

  useEffect(() => {
    // format the user list
    let list = [];
    if (isEmpty(organization) || isEmpty(orgMembers)) return;

    if (orgMembers?.users?.length) {
      list = orgMembers.users.map((user) => {
        const prAgentConnectedProviders = ['github', 'gitlab', 'bitbucket'];

        const providers = [];

        prAgentConnectedProviders.forEach((provider) => {
          const userIdField = `${provider}_user_id`;
          const userNameField = `${provider}_user_name`;
          if (user[userIdField] && user[userNameField]) {
            providers.push(
              <Tooltip key={provider} content={`Enabled in ${provider}`}>
                <img
                  className={'w-6'}
                  alt="git_provider"
                  src={gitProvidersConnections[provider].icon}
                />
              </Tooltip>,
            );
          }
        });

        const team = find(orgMembers.teams, { id: user.teams?.[0]?.team_id });

        const row = {
          id: user.id,
          avatar: user.avatar,
          name: user.name,
          email: user.email,
          date_created: user.date_created,
          'Team Role': getTeamLabel(team, user),
          'Organization Role': (
            <div className="flex gap-2">
              <OrgPermission user={user} />
            </div>
          ),
          Environment: (
            <div className="flex gap-2">
              <Environment user={user} />
            </div>
          ),
          status: user.verified ? 'Verified' : 'Registered',
          'PR-Agent Pro': providers.length ? (
            <div className="flex gap-2">{providers}</div>
          ) : (
            'Not Connected'
          ),
        };

        if (organization?.invite_only) {
          row['actions'] = {
            custom: [
              {
                action: removeUser,
                icon: 'trash',
              },
            ],
          };
        }

        return row;
      });
    }

    if (orgMembers?.invites?.length) {
      orgMembers.invites.forEach((x) => {
        list.push({
          id: x.id,
          avatar: x.avatar,
          name: x.name,
          email: x.email,
          date_created: x.date_sent,
          'Team Role': '',
          'Organization Role': '',
          Environment: '',
          status: 'Invited',
          'PR-Agent Pro': '',
          actions: organization?.invite_only ? { custom: [] } : undefined,
        });
      });
    }

    setUsers(list);
  }, [organization, orgMembers]);

  const removeUser = (user) => {
    mixpanelService.trackEvent(events.REMOVE_MEMBER_FROM_ORGANIZATIONS_CLICK, {
      organization_id: organization?.id,
    });
    const encodedUserId = encodeURIComponent(user.id);
    context.modal.show(
      {
        title: 'Remove User',
        form: {},
        buttonText: 'Remove User',
        text: `Are you sure you want to remove ${user.name}?`,
        url: `/platform/v2/organizations/users/${encodedUserId}`,
        method: 'DELETE',
        destructive: true,
      },
      () => {
        context.notification.show(user.name + ' was deleted', 'success', true);
        refetchOrgMembers();
      },
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <PortalNav />
      {isPending && <Loader center />}
      {!isPending && (
        <Animate>
          <>
            <TitleRow className={'my-5'} title={<OrgTitle />}>
              <div className="flex gap-2">
                <ManageOrgDropdown
                  createTeam={() =>
                    addTeam({
                      adminOptions: orgMembers?.users?.map((member) => ({
                        label: member.name,
                        value: member.id,
                      })),
                      callback: refetchOrgMembers,
                    })
                  }
                />
                <Button
                  small
                  text="See invoices"
                  action={() => handleCreateStripeSession()}
                />
                <Button
                  small
                  text="Invite Members"
                  action={inviteMembersToOrg}
                />
              </div>
            </TitleRow>
            {orgMembers && (
              <Card>
                <Table
                  search={false}
                  className="restrict-width"
                  data={users}
                  loading={isPending}
                  show={[
                    'name',
                    'email',
                    'Team Role',
                    'Organization Role',
                    !isEmpty(organization?.baseUrlOptions) && 'Environment',
                    'status',
                    'PR-Agent Pro',
                  ]}
                  badge={{
                    col: 'status',
                    color: 'green',
                    condition: [
                      { value: 'verified', color: 'green' },
                      { value: 'registered', color: 'green' },
                      { value: 'invited', color: 'purple' },
                    ],
                  }}
                />
              </Card>
            )}
          </>
        </Animate>
      )}
    </div>
  );
};
