/***
 *
 *   USER
 *   Shows the current user name and avatar
 *   If user belongs to more than one account, they can switch accounts here
 *
 **********/

import { AuthContext, Badge, Dropdown, Icon } from 'components/lib';
import { useContext, useState } from 'react';
import { UpgradeNow } from './upgradeNow.jsx';
import Style from './user.tailwind.js';
import { useUser } from 'components/hooks/useUser.js';
import { TIER_LEVELS, TIER_LEVEL_TO_TITLE } from 'constants.js';
import { UserSettingsDialog } from './userSettingsDialog';

export const User = () => {
  const authContext = useContext(AuthContext);
  const { user, isTrialUser } = useUser();
  const [isSettingsDialogOpen, setSettingsDialogOpen] = useState(false);

  return (
    <>
      <div className={Style.user}>
        <div className="flex gap-2 items-center">
          <UpgradeNow />
          <div>
            <Dropdown>
              <>
                <Dropdown.Trigger>
                  <div className="flex align-center justify-center gap-1 px-1 w-[64px] h-[38px] bg-gradient-to-r from-[#05E5AD] to-[#6447FF] rounded-2xl cursor-pointer">
                    <Icon
                      image="user"
                      color="#171717"
                      size={24}
                      className="border-2 rounded-full border-[#171717] self-center"
                    />
                    <Icon
                      image="chevron-down"
                      color="#171717"
                      size={20}
                      className="self-center"
                    />
                  </div>
                </Dropdown.Trigger>
                <Dropdown.Menu>
                  <div className="min-w-[250px]">
                    <Dropdown.MenuItem className={Style.menuItem} disabled>
                      <div className="flex flex-col gap-1 cursor-default">
                        <div className="text-sm text-gray-500">Account</div>
                        <div className="font-medium text-sm">{user?.email}</div>
                      </div>
                    </Dropdown.MenuItem>
                    <Dropdown.MenuItem
                      className={Style.menuItem}
                      onSelect={() => false}
                    >
                      <div className="flex justify-between gap-4 cursor-default">
                        <div className="text-sm text-gray-500">Plan</div>
                        <div className="flex font-medium text-sm gap-0.5">
                          {user?.tier_level && !isTrialUser && (
                            <Badge
                              className="bg-gradient-to-br from-[#48FED2] to-[#05AFE5] text-gray-950 rounded-[14px]"
                              text={TIER_LEVEL_TO_TITLE[user.tier_level]}
                            />
                          )}
                          {user?.tier_level >= TIER_LEVELS.PAID && (
                            <Badge
                              className="bg-gradient-to-br from-[#8D64FF] to-[#BB9BFF] text-gray-950 rounded-[14px]"
                              text="PR-AGENT PRO"
                            />
                          )}
                        </div>
                      </div>
                    </Dropdown.MenuItem>
                    <Dropdown.MenuItem
                      className={Style.menuItem}
                      onSelect={() => setSettingsDialogOpen(true)}
                    >
                      Account settings
                    </Dropdown.MenuItem>
                    <Dropdown.MenuItem
                      className={Style.menuItem}
                      onSelect={() =>
                        window.open('https://codium.ai/placeholder/', '_blank')
                      }
                    >
                      Go to Docs
                    </Dropdown.MenuItem>
                    <Dropdown.MenuItem onSelect={authContext.signout}>
                      Sign out
                    </Dropdown.MenuItem>
                  </div>
                </Dropdown.Menu>
              </>
            </Dropdown>
          </div>
        </div>
      </div>
      {isSettingsDialogOpen && (
        <UserSettingsDialog closeModal={() => setSettingsDialogOpen(false)} />
      )}
    </>
  );
};
