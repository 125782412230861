import JetBrainLogo from 'assets/JetBrains.svg';
import Intellij from 'assets/intellij.svg';
import PyCharm from 'assets/PyCharm.svg';
import WebStorm from 'assets/Webstorm.svg';
import VSCode from 'assets/VSCode.svg';
import PersonalCardIcon from 'assets/personal-card.svg';
import ChartIcon from 'assets/chart.svg';
import UserCircleAddIcon from 'assets/user-circle-add.svg';
import People from 'assets/people.svg';
import {
  Animate,
  AuthContext,
  Card,
  ViewContext,
  useInvite,
  useNavigate,
} from 'components/lib';

import { useTeam } from 'components/hooks/useTeam';
import { useUser } from 'components/hooks/useUser';
import { PortalNav } from 'components/nav/sub/portal';
import { useContext } from 'react';
import { openUrl } from 'utils';
import { events, mixpanelService } from '../../app/analytics';
import { HomeActionCard } from './homeActionCard';
import { Invitation } from './invitation';
import { PrAgentButtons } from './prAgentButtons';
import { TIER_LEVELS } from '../../constants';
import { RequestAccess } from './requestAccess';

const discordURL = 'https://discord.com/invite/SgSxuQ65GF';
const TRIAL_MESSAGE_FOR_NEW_USERS =
  'Your 14-days free trial of our Teams plan starts today. Enjoy!';
const links = [
  {
    name: 'VSCode',
    imgUrl: VSCode,
    url: 'https://marketplace.visualstudio.com/items?itemName=Codium.codium',
  },
  {
    name: 'JetBrains',
    imgUrl: JetBrainLogo,
    url: 'https://plugins.jetbrains.com/plugin/21206-codiumai--integrity-agent-powered-by-gpt-3-5-4',
  },
  {
    name: 'PyCharm',
    imgUrl: PyCharm,
    url: 'https://plugins.jetbrains.com/plugin/21206-codiumai--integrity-agent-powered-by-gpt-3-5-4',
  },
  {
    name: 'InteliJ',
    imgUrl: Intellij,
    url: 'https://plugins.jetbrains.com/plugin/21206-codiumai--integrity-agent-powered-by-gpt-3-5-4',
  },
  {
    name: 'WebStorm',
    imgUrl: WebStorm,
    url: 'https://plugins.jetbrains.com/plugin/21206-codiumai--integrity-agent-powered-by-gpt-3-5-4',
  },
];

export const Home = () => {
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const { user, dayLeftForTrial } = useUser();
  const { team, handleCreateStripeSession, isLoading } = useTeam(
    authContext?.user?.team_id,
  );
  const cache = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  const { inviteMembersToTeam } = useInvite({ teamId: team?.id });

  const isEnterprise = user?.tier_level === TIER_LEVELS.ENTERPRISE;
  const userTeamId = user?.teams?.[0]?.team_id;
  const isTeamOwner =
    user?.permission === 'owner' && team && userTeamId === team?.id;

  const homeActions = [
    {
      title: 'Join CodiumAI Discord community',
      subTitle: 'Join Discord',
      icon: People,
      action: () => {
        mixpanelService.trackEvent(events.JOIN_DISCORD_CLICK);
        openUrl(discordURL);
      },
      className: 'bg-gradient-to-br from-[#382b4cb1] from-10%',
      iconClassName: 'border-[#6E598A]',
    },
    {
      title: 'Invite members to your team',
      subTitle: 'Invite members',
      icon: UserCircleAddIcon,
      action: () => {
        const teamId = authContext?.user?.team_id;
        if (!teamId) {
          viewContext.notification.show(
            'Please create a team first',
            'error',
            true,
            'toast',
            'alert-triangle',
          );
          navigate(`/team`);
          return;
        }
        inviteMembersToTeam((form, res) => {
          navigate(`/team/${teamId}/users`);
          viewContext.notification.show(
            res.message,
            'success',
            true,
            'toast',
            'heart',
          );
        });
      },
    },
    {
      isHidden: isEnterprise || !isTeamOwner,
      title: 'Manage and upgrade your plan',
      isDisabled: isLoading,
      icon: PersonalCardIcon,
      subTitle: 'Manage plans',
      action: () => handleCreateStripeSession({ isManage: true }),
    },
    {
      isHidden: authContext.user.team_id,
      title: 'Upgrade you plan',
      icon: ChartIcon,
      subTitle: 'Compare plans',
      action: () => navigate('/plans'),
    },
  ];

  return (
    <div className="flex flex-col gap-3 md:gap-6">
      <PortalNav />
      <Animate>
        <div className="flex flex-col gap-3 md:gap-6">
          <div className="flex flex-col justify-between ">
            <span className="dark:text-stone-300 text-base md:text-lg font-semibold">
              {`Welcome to CodiumAI, ${cache?.displayName || 'User'}`}
            </span>
            <span className={'dark:text-stone-400'}>
              {dayLeftForTrial === 14 && TRIAL_MESSAGE_FOR_NEW_USERS}
            </span>
          </div>
          <RequestAccess />
          <Invitation />
          <div className="flex flex-row gap-4">
            <Card className="flex flex-col flex-1 items-start space-y-4 md:space-y-6 p-3 md:p-4 rounded-md self-stretch dark:bg-zinc-800">
              <div className="flex justify-between w-full">
                <span className={'dark:text-stone-300 text-sm md:text-base'}>
                  Install CodiumAI extension in your IDE
                </span>
              </div>
              <div className="flex flex-col md:flex-row justify-between w-full">
                <div className="flex flex-col items-start gap-2 md:gap-4">
                  <span className="text-xs md:text-sm text-neutral-400">
                    Currently supported
                  </span>
                  <div className="flex gap-2">
                    {links.map((item) => (
                      <div
                        className="flex items-center space-x-2 md:space-x-4 p-3 md:p-4 rounded-md dark:bg-zinc-700 border border-zinc-600 hover:cursor-pointer hover:scale-110 transition-transform"
                        onClick={() => {
                          const eventName =
                            item.name === 'VSCode'
                              ? events.VSCODE_MARKETPLACE_LINK_CLICK
                              : events.JB_MARKETPLACE_LINK_CLICK;
                          mixpanelService.trackEvent(eventName, {
                            ideType: item.name,
                          });
                          openUrl(item.url, true);
                        }}
                        key={item.name}
                      >
                        <img width={'24px'} src={item.imgUrl} alt={item.name} />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-col items-end gap-2 md:gap-4"></div>
              </div>
            </Card>
            {user?.tier_level >= TIER_LEVELS.TRIAL && (
              <Card className="flex flex-col flex-1 items-start space-y-4 md:space-y-6 p-3 md:p-4 rounded-md self-stretch dark:bg-zinc-800">
                <div className="flex justify-between w-full">
                  <span className={'dark:text-stone-300 text-sm md:text-base'}>
                    Connect PR-Agent to your repositories
                  </span>
                </div>
                <div className="flex flex-col md:flex-row justify-between w-full">
                  <div className="flex flex-col items-start gap-2 md:gap-4">
                    <span className="text-xs md:text-sm text-neutral-400">
                      Currently supported
                    </span>
                    <PrAgentButtons />
                  </div>
                  <div className="flex flex-col items-end gap-2 md:gap-4"></div>
                </div>
              </Card>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-3 md:gap-6">
          <span className="dark:text-stone-300 text-base md:text-lg">
            You might also want to
          </span>
          <div className="flex flex-col items-start space-y-4 md:space-y-6 rounded-md self-stretch w-full">
            <div className="flex flex-wrap gap-3 md:gap-4 w-full">
              {homeActions.map(
                (action) =>
                  !action.isHidden && (
                    <HomeActionCard key={action.title} action={action} />
                  ),
              )}
            </div>
          </div>
        </div>
      </Animate>
    </div>
  );
};
