import React, { useState } from 'react';
import { TextInput } from '../input/text';
import { Button } from '../../button/button';
import { last, isEmpty } from 'lodash';

export function OptionsEditor(props) {
  const { label, options, onChange, name } = props;
  const [updatedOptions, setUpdatedOptions] = useState(options);

  const formatValue = (val) =>
    val.map((option, i) => ({ name: option.label, baseUrl: option.value }));

  const handleOptionLabelChange = (index) => {
    return (name, label, valid) => {
      const val = updatedOptions.map((option, i) =>
        i === index ? { ...option, label } : option,
      );
      setUpdatedOptions(val);
      onChange?.(name, formatValue(val), valid);
    };
  };

  const handleOptionValueChange = (index) => {
    return (name, value, valid) => {
      const val = updatedOptions.map((option, i) =>
        i === index ? { ...option, value } : option,
      );
      setUpdatedOptions(val);
      onChange?.(name, formatValue(val), valid);
    };
  };

  const handleAddOption = () => {
    const newOption = { label: '', value: '' };
    const val = [...updatedOptions, newOption];
    setUpdatedOptions(val);
    onChange?.(name, formatValue(val), undefined);
  };

  return (
    <div className="flex flex-col gap-2 mb-4">
      <div>{label}</div>
      <div className="flex flex-col gap-2 h-64 overflow-y-auto">
        {updatedOptions?.map((opt, index) => (
          <div key={index}>
            {index < options.length && (
              <TextInput
                {...props}
                onChange={handleOptionLabelChange(index)}
                label={opt.value}
                value={opt.label}
              />
            )}
            {index === options.length - 1 && (
              <div className="underline">New Values:</div>
            )}
            {index >= options.length && (
              <>
                <TextInput
                  {...props}
                  onChange={handleOptionLabelChange(index)}
                  label="label"
                  value={opt.label}
                />
                <TextInput
                  {...props}
                  onChange={handleOptionValueChange(index)}
                  label="value"
                  value={opt.value}
                />
              </>
            )}
          </div>
        ))}
      </div>
      <Button
        icon="plus"
        iconButton
        text="Add base url"
        className="mb-3 bg-zinc-700"
        rounded
        action={handleAddOption}
        isDisabled={isEmpty(last(updatedOptions).value)}
      />
    </div>
  );
}
