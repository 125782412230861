/***
 *
 *   AUTHENTICATION
 *   Auth provider to manage auth functions throughout
 *   the application. <PrivateRoute> component to
 *   protect internal application routes from unauthenticated
 *   access.
 *
 **********/

import { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

const customClaimsKeys = [
  'team_id',
  'team_name',
  'permission',
  'organization_permission',
  'tier_level',
];
// auth context
export const AuthContext = createContext();

const permissions = require('./permissions');
const PARENT_DOMAIN = window.location.hostname.replace(
  'app.codium.ai',
  'codium.ai',
);

export function AuthProvider(props) {
  const cachedUser = JSON.parse(localStorage.getItem('user'));
  const auth = getAuth();

  const [isInitialized, setIsInitialized] = useState(false);
  const [user, setUser] = useState(cachedUser);

  const goToPathname = (newPathname) => {
    if (window.location.pathname === newPathname) return;
    return (window.location = newPathname);
  };

  const updateUser = async (user, forceRefresh = false) => {
    if (!user) {
      goToPathname('/signin');
      return;
    }
    setUser(user);
    localStorage.setItem('user', JSON.stringify(user));
    const token = await user.getIdToken(true);
    const decodedToken = jwtDecode(token);
    const updatedUser = updateUserCustomClaims(decodedToken);
    Cookies.set(
      'codium_user',
      JSON.stringify(
        Object.fromEntries(
          Object.entries(updatedUser).filter(([k]) =>
            [
              'email',
              'permission',
              'organization_permission',
              'team_id',
              'team_name',
              'tier_level',
            ].includes(k),
          ),
        ),
      ),
      { domain: `.${PARENT_DOMAIN}`, sameSite: 'strict', secure: true },
    );
  };

  const refreshUser = async () => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken(true);
      const decodedToken = jwtDecode(token);
      setUser(user);
      updateUserCustomClaims(decodedToken);
      localStorage.setItem('user', JSON.stringify(user));
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
  };

  const updateUserCustomClaims = (customClaims) => {
    if (customClaims) {
      const filteredCustomClaims = Object.keys(customClaims)
        .filter((key) => customClaimsKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = customClaims[key];
          return obj;
        }, {});

      const { team_id } = filteredCustomClaims;
      return update({ ...filteredCustomClaims, teams: [team_id] });
    }
  };

  // set user and token on mount
  useEffect(() => {
    const unsubscribeUser = auth.onAuthStateChanged(async (user) => {
      await updateUser(user);
      setIsInitialized(true);
    });
    return () => {
      unsubscribeUser();
    };
  }, []);

  async function signout() {
    signOut(auth).then(() => {
      console.log('User signed out');
    });
    localStorage.clear();
    Cookies.remove('codium_user', {
      domain: `.${PARENT_DOMAIN}`,
      sameSite: 'strict',
      secure: true,
    });
    goToPathname('/signin');
  }

  function update(data) {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      for (let key in data) {
        if (Array.isArray(data[key])) {
          user[key] = data[key];
        } else if (typeof data[key] === 'object') {
          for (let innerKey in data[key]) {
            user[key][innerKey] = data[key][innerKey];
          }
        } else {
          user[key] = data[key];
        }
      }

      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);
      return user;
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isInitialized,
        user,
        signout,
        update,
        refreshUser,
        permission: permissions[user?.permission],
      }}
      {...props}
    />
  );
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props) {
  // check user exists
  const user = JSON.parse(localStorage.getItem('user'));
  const path = window.location.pathname;
  const permittedRoutes = [
    '/account/billing',
    '/signup/plan',
    '/account/upgrade',
    '/account',
    '/account/profile',
  ];

  if (user?.token) {
    if (permissions[user.permission][props.permission]) {
      if (user.verified) {
        // user has no plan
        if (
          !user.plan &&
          path !== '/account/profile' &&
          path !== '/signup/plan'
        )
          return <Navigate to="/signup/plan" />;

        // user has no subscription
        if (
          user.subscription !== 'active' &&
          user.subscription !== 'trialing' &&
          user.permission !== 'master' &&
          !permittedRoutes.includes(path)
        )
          return <Navigate to="/account/billing" />;

        // hide verified view if user is verified
        if (path === '/signup/verify') return <Navigate to="/dashboard" />;
      } else {
        // user is not verified
        // if (path !== '/account/profile' && path !== '/signup/verify')
        //   return <Navigate to='/signup/verify' />;
      }

      // user is good
      return props.children;
    }
  }

  // user is not authenticated
  return <Navigate to="/signin" />;
}
