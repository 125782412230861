/***
 *
 *   LOGO
 *   Replace the images in /images with your own logo
 *
 *   PROPS
 *   color: toggle between brand color or white logo (string, optional, default: white)
 *   mark: use a logo mark or full logo (boolean, optional: default: full logo)
 *
 **********/

import { useContext } from 'react';
import { Link, ClassHelper, AuthContext } from 'components/lib';
import CodiumLogo from './images/codiumai-logo.svg';
import Style from './logo.tailwind.js';

export function Logo(props) {
  const logoStyle = ClassHelper(Style, props);
  const { user } = useContext(AuthContext);

  return (
    <Link url={user ? '/' : ''} className={logoStyle} disabled={!user}>
      <img src={CodiumLogo} alt="Logo" />
    </Link>
  );
}
